.collapse-top {
    display: block !important;
    overflow: hidden;
    transition: height 1s ease !important;
}

.collapse-top:not(.show) {
    display: block !important;
    height: 0px !important;
}

.collapse-bottom {
    display: block !important; 
    overflow: hidden;
    transition: height 1s ease !important;
}

.collapse-bottom:not(.show) {
    display: block !important;
    height: 0px !important;
}

.collapse-start {
    display: block !important;   
    overflow: hidden;
    transition: width 1s ease !important;
}

.collapse-start:not(.show) {
    display: block !important;
    width: 0px !important;
}

.collapse-end {
    display: block !important;   
    overflow: hidden;
    transition: width 1s ease !important;
}

.collapse-end:not(.show) {
    display: block !important;
    width: 0px !important;
}



/* .show {
    display: block !important;    
  }

.collapsed {
    display: block !important;
    height: 0px !important;
    width: 0px !important;
} */

