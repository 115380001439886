html, body {
	width: 100%;
	height: 100%;
}

#root {
	width: 100%;
	height: 100%;
}

.vtext {
	writing-mode: vertical-rl;
	text-orientation: mixed;
}

.w-0 {
	width: 0px !important;
}
