html, body {
	background-color: var(--bs-body-bg) !important;
	color: var(--bs-body-color) !important;
	transition: background-color 0.5s ease;
}

div {
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-primary {
	background-color: var(--bs-primary);
	border-color: var(--bs-primary);
	color: var(--bs-primary-text);
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-secondary {
	background-color: var(--bs-secondary);
	border-color: var(--bs-secondary);
	color: var(--bs-secondary-text);
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-success {
	background-color: var(--bs-success);
	border-color: var(--bs-success);
	color: var(--bs-success-text);
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-info {
	background-color: var(--bs-info);
	border-color: var(--bs-info);
	color: var(--bs-info-text);
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-warning {
	background-color: var(--bs-warning);
	border-color: var(--bs-warning);
	color: var(--bs-warning-text);
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-danger {
	background-color: var(--bs-danger);
	border-color: var(--bs-danger);
	color: var(--bs-danger-text);
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.border-primary {
    border-color: var(--bs-primary) !important;
}

.border-secondary {
    border-color: var(--bs-secondary) !important;
}

.border-success {
    border-color: var(--bs-success) !important;
}

.border-info {
    border-color: var(--bs-info) !important;
}

.border-warning {
    border-color: var(--bs-warning) !important;
}

.border-danger {
    border-color: var(--bs-danger) !important;
}

.btn-primary {
	background-color: var(--bs-primary) !important;
	border-color: var(--bs-primary) !important;
	color: var(--bs-primary-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-primary:hover {
	background-color: var(--bs-primary-hover) !important;
	/* border-color: var(--bs-primary-hover) !important; */
	color: var(--bs-primary-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;	
}

.btn-primary:focus {
	background-color: var(--bs-primary) !important;
	border-color: var(--bs-primary) !important;
	/* box-shadow: 0 0 0 0.25rem var(--bs-primary-hover-rgb) !important; */
	color: var(--bs-primary-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-secondary {
	background-color: var(--bs-secondary) !important;
	border-color: var(--bs-secondary) !important;
	color: var(--bs-secondary-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-secondary:hover {
	background-color: var(--bs-secondary-hover) !important;
	/* border-color: var(--bs-secondary-hover) !important; */
	color: var(--bs-secondary-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;	
}

.btn-secondary:focus {
	background-color: var(--bs-secondary-hover) !important;
	/* border-color: var(--bs-secondary-hover) !important; */
	box-shadow: 0 0 0 0.25rem var(--bs-secondary-hover-rgb) !important;
	color: var(--bs-secondary-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-success {
	background-color: var(--bs-success) !important;
	border-color: var(--bs-success) !important;
	color: var(--bs-success-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-success:hover {
	background-color: var(--bs-success-hover) !important;
	border-color: var(--bs-success-hover) !important;
	color: var(--bs-success-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;	
}

.btn-success:focus {
	background-color: var(--bs-success-hover) !important;
	border-color: var(--bs-success-hover) !important;
	box-shadow: 0 0 0 0.25rem var(--bs-success-hover-rgb) !important;
	color: var(--bs-success-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-info {
	background-color: var(--bs-info) !important;
	border-color: var(--bs-info) !important;
	color: var(--bs-info-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-info:hover {
	background-color: var(--bs-info-hover) !important;
	border-color: var(--bs-info-hover) !important;
	color: var(--bs-info-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;	
}

.btn-info:focus {
	background-color: var(--bs-info-hover) !important;
	border-color: var(--bs-info-hover) !important;
	box-shadow: 0 0 0 0.25rem var(--bs-info-hover-rgb) !important;
	color: var(--bs-info-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-warning {
	background-color: var(--bs-warning) !important;
	border-color: var(--bs-warning) !important;
	color: var(--bs-warning-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-warning:hover {
	background-color: var(--bs-warning-hover) !important;
	border-color: var(--bs-warning-hover) !important;
	color: var(--bs-warning-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;	
}

.btn-warning:focus {
	background-color: var(--bs-warning-hover) !important;
	border-color: var(--bs-warning-hover) !important;
	box-shadow: 0 0 0 0.25rem var(--bs-warning-hover-rgb) !important;
	color: var(--bs-warning-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-danger {
	background-color: var(--bs-danger) !important;
	border-color: var(--bs-danger) !important;
	color: var(--bs-danger-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-danger:hover {
	background-color: var(--bs-danger-hover) !important;
	border-color: var(--bs-danger-hover) !important;
	color: var(--bs-danger-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;	
}

.btn-danger:focus {
	background-color: var(--bs-danger-hover) !important;
	border-color: var(--bs-danger-hover) !important;
	box-shadow: 0 0 0 0.25rem var(--bs-danger-hover-rgb) !important;
	color: var(--bs-danger-text) !important;
	transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.collapsing.collapse-horizontal {
	width: 0;
	height: auto;
	transition: width 1s ease;
}

a {
	color: inherit;
}

a:hover {
	color: inherit;
}